import { useMediaQuery } from "react-responsive";
import { SIZES } from "../utils/mediaQueries";

const useMediaQueries = (): any => {
  return Object.keys(SIZES).reduce(
    (acc, device) => ({
      ...acc,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      [device]: useMediaQuery({
        minWidth: SIZES[device],
      }),
    }),
    {},
  );
};
export default useMediaQueries;
