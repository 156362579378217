import type { GetStores_stores } from "../../stores/hooks/types/GetStores";
import type { InternalNavItem } from "../types";

const makeNavItemFromStore = (
  store: GetStores_stores,
  currentNavItem: InternalNavItem,
): InternalNavItem => {
  const detailPageSuffix = `/${store.slug}-${store.id}`;

  return {
    title: store?.name,
    href: `${currentNavItem.href}${detailPageSuffix}`,
    path: `${currentNavItem.path}${detailPageSuffix}`,
    slug: `${currentNavItem.slug}-${store.slug}`,
    hasChildren: false,
    palette: currentNavItem.palette,
    type: currentNavItem.type,
  };
};

export default makeNavItemFromStore;
