interface Price {
  amount: number;
}

const addThousandSeparator = (value: string): string =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
const formatNumber = (value: number) => addThousandSeparator(value.toFixed(2));
const centsToFrancs = (price: Price): number => price?.amount / 100;

export const formatPrice = (price: Price): string =>
  formatNumber(centsToFrancs(price));
