import React from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg<{ color: Color }>`
  height: 15px;
  * {
    stroke: ${selectColor((p) => p.color)};
  }
`;

export interface CheckIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: Color;
}

const CheckIcon: React.FC<CheckIconProps> = ({
  style,
  className,
  color = "green",
}) => (
  <SVG viewBox="1.6 2 10 10" style={style} className={className} color={color}>
    <g fill="none" fillRule="evenodd">
      <path strokeWidth="2" d="M3.038 7.208L5.768 10l4.252-6.966" />
    </g>
  </SVG>
);

export default CheckIcon;
