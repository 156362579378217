import React from "react";
import { useFullPage } from "../hooks/useFullPage";
import Content from "./Content";

export interface ContentPageIntroProps {
  path: string;
}

const ContentPageIntro: React.FC<ContentPageIntroProps> = ({ path }) => {
  const { data } = useFullPage(path);

  if (!data || !data.page) {
    return null;
  }

  const { page } = data;

  if (!page) {
    return null;
  }

  return (
    <Content
      content={page.introContent}
      configSelection={{
        resource: "Page",
        area: "intro",
        pageTemplate: page.pageTemplate,
      }}
      inplaceEditing={{
        adminResource: "Page",
        fieldName: "introContent",
        recordId: page.id,
      }}
    />
  );
};

export default ContentPageIntro;
