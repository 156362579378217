import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { GetFullPage, GetFullPageVariables } from "./types/GetFullPage";
import { PageBaseParts } from "../../navigation/queries";

export const QUERY = gql`
  query GetFullPage($path: String!) {
    page(path: $path) {
      ...PageBaseParts
      content
      introContent

      htmlTitle

      social {
        title
        description
        image
      }
      meta {
        description
      }
    }
  }
  ${PageBaseParts}
`;

export const useFullPage = (path: string) => {
  return useQuery<GetFullPage, GetFullPageVariables>(QUERY, {
    variables: {
      path,
    },
  });
};
