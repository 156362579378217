import React from "react";
import styled from "styled-components";
import { useFullPage } from "../hooks/useFullPage";
import Content from "./Content";

const Base = styled.div``;

export interface ContentPageContentProps {
  path: string;
}

const ContentPageContent: React.FC<ContentPageContentProps> = ({ path }) => {
  const { loading, data } = useFullPage(path);

  const page = data?.page;
  if (!page && loading) {
    return null;
  }
  if (!page && !loading) {
    return <p>Page not found: {path}</p>;
  }

  return (
    <Base>
      {page.content ? (
        <Content
          configSelection={{
            area: "content",
            resource: "Page",
            pageTemplate: page.pageTemplate,
          }}
          inplaceEditing={{
            adminResource: "Page",
            fieldName: "content",
            recordId: page.id,
          }}
          content={page.content}
        />
      ) : null}
    </Base>
  );
};

export default React.memo(ContentPageContent);
