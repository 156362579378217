import React from "react";
import styled from "styled-components";

const Base = styled.svg`
  width: 21px;
  height: 21px;
`;

export interface MinusIconProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: any;
  inverted?: boolean;
}

const MinusIcon: React.FC<MinusIconProps> = ({
  style,
  className,
  inverted,
}) => (
  <Base style={style} className={className} viewBox="0 0 21 21">
    <g fillRule="evenodd">
      <circle
        stroke={inverted ? undefined : "#478392"}
        fill={inverted ? "#478392" : "none"}
        strokeWidth="1.5"
        cx="10.5"
        cy="10.5"
        r="9.5"
      />
      <path
        fill={inverted ? "#edebe2" : "#478392"}
        d="M7.35 11.667v-1.391h6.3v1.391h-1.162z"
      />
    </g>
  </Base>
);

export default MinusIcon;
