type WithTags = {
  readonly tags: ReadonlyArray<string> | null;
};
export const isLowPrice = (product: WithTags) =>
  product.tags?.includes("LOW_PRICE_DISCOUNTED");

export const isMonthly = (product: WithTags) =>
  product.tags?.includes("MONTHLY_DISCOUNTED");

export const isBikeDelivery = (product: WithTags) =>
  product.tags?.includes("BICYCLE_DELIVERY");
