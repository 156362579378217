import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { PageBaseParts } from "../queries";
import type {
  UsePageForNavigation,
  UsePageForNavigationVariables,
} from "./types/UsePageForNavigation";

export const GET_PAGE_FOR_NAVIGATION = gql`
  query UsePageForNavigation($path: String) {
    page(path: $path) {
      ...PageBaseParts
      childPages {
        ...PageBaseParts
        childPages {
          ...PageBaseParts
        }
      }
    }
  }
  ${PageBaseParts}
`;

const usePageForNavigation = (path: string) => {
  const { data, previousData, error, loading } = useQuery<
    UsePageForNavigation,
    UsePageForNavigationVariables
  >(GET_PAGE_FOR_NAVIGATION, {
    variables: { path },
    skip: !path,
  });
  const page = (data ?? previousData)?.page;

  return { page, error, loading };
};

export default usePageForNavigation;
