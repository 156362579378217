import dynamic from "next/dynamic";
import React from "react";
import ContentPageContent from "../../content/components/ContentPageContent";
import ContentPageHead from "../../content/components/ContentPageHead";
import ContentPageIntro from "../../content/components/ContentPageIntro";
import usePagePaths from "../../content/hooks/usePagePaths";
import PageLayout from "../../layout/components/PageLayout";
import type { NavItemsQueryType } from "../../navigation/types";

const StoreDetail = dynamic(() => import("./StoreDetail"));

const StorePageContent = ({
  path,
  NavItemsQuery,
}: {
  path: string;
  NavItemsQuery: NavItemsQueryType;
}) => {
  const { subPageId: storeId } = usePagePaths();

  return (
    <PageLayout
      showDesktopSubNavigation
      NavItemsQuery={NavItemsQuery}
      showBreadcrumbs
    >
      {storeId ? (
        <StoreDetail storeId={storeId} />
      ) : (
        <>
          <ContentPageHead path={path} />
          <ContentPageIntro path={path} />
          <ContentPageContent path={path} />
        </>
      )}
    </PageLayout>
  );
};

export default React.memo(StorePageContent);
