import React from "react";
import styled from "styled-components";
import type { Font } from "../../../config/theme";
import { formatPrice } from "../../../utils/formatPrice";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";

const Base = styled.div``;

const Amount = styled.span``;

const priceOriginalLineHeight = 1;

const ProductPrice = styled.div<{
  font: Font;
  original: boolean;
  discount: boolean;
}>`
  ${(p) => selectFont(p.font)};
  position: relative;

  ${iff((p) => p.original)`
    padding-left: 2px;
    padding-right: 2px;
    color: ${selectColor("textGrey")};
    &:after {
      content: "";
      border-bottom: ${priceOriginalLineHeight}px solid ${selectColor(
    "textGrey",
  )};
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% + ${priceOriginalLineHeight}px);
    }
  `}

  ${iff((p) => p.discount)`
     color: ${selectColor("veloplusRot")};
  `}
`;

export interface PriceProps {
  style?: React.CSSProperties;
  className?: string;
  price: {
    amount: number;
    currency: string;
  };
  priceStyle?: React.CSSProperties;
  prefix?: any;
  original?: boolean;
  discount?: boolean;
  font?: Font;
  hideCurrency?: boolean;
}

const Price: React.FC<PriceProps> = ({
  prefix = null,
  style,
  priceStyle,
  className,
  price,
  original = false,
  discount = false,
  font = "medium",
  hideCurrency = false,
}) => {
  if (!price) {
    return <br />;
  }
  return (
    <Base style={style} className={className}>
      <ProductPrice
        style={priceStyle}
        font={font}
        original={original}
        discount={discount}
      >
        {prefix}
        {price.currency && !hideCurrency && `${price.currency} `}
        <Amount>{formatPrice(price)}</Amount>
      </ProductPrice>
    </Base>
  );
};

export default Price;
