import { useRouter } from "next/router";
import React, { memo, useEffect } from "react";
import { browserScrollToWithOffset } from "../../../lib/browser";
import { MenuId, PageTemplate } from "../../../types/global-types";
import ShopNavItemsQuery from "../../categories/components/ShopNavItemsQuery";
import JobPageContent from "../../jobs/components/JobPageContent";
import PageLayout from "../../layout/components/PageLayout";
import useBreadcrumbPaths from "../../navigation/hooks/useBreadcrumbPaths";
import usePageBaseProps from "../../navigation/hooks/usePageBaseProps";
import type { NavItemsQueryType } from "../../navigation/types";
import StorePageContent from "../../stores/components/StorePageContent";
import TipsPageContent from "../../tips/components/TipsPageContent";
import TravelReportPageContent from "../../travelReports/components/TravelReportPageContent";
import { PagePathsProvider } from "../hooks/usePagePaths";
import ContentPageContent from "./ContentPageContent";
import ContentPageHead from "./ContentPageHead";
import ContentPageIntro from "./ContentPageIntro";
import PageNavItemsQuery from "./PageNavItemsQuery";

const useShouldShowShopNavi = (path: string) => {
  const breadcrumbPaths = useBreadcrumbPaths(path);
  const { page: rootPage } = usePageBaseProps(breadcrumbPaths[0].path);

  if (path === "/" || path === "/404" || path === "/shop") {
    return true;
  }
  if (!rootPage) {
    return false;
  }
  // if rootPage has no children, show main menu if its not in the main root
  if (rootPage.menuId !== MenuId.MAIN && !rootPage?.childPages?.length) {
    return true;
  }

  return false;
};

// for CONTENT (leave), LANDING and ROOT
const DefaultPageContent = ({
  path,
  NavItemsQuery,
}: {
  path: string;
  NavItemsQuery: NavItemsQueryType;
}) => {
  const router = useRouter();

  const { page } = usePageBaseProps(path);

  const pageTemplate = page?.pageTemplate ?? null;
  const showShopNavi = useShouldShowShopNavi(
    (router.query?.menuPath as string) ?? path,
  );

  const fullWidthIntro = pageTemplate === "LANDING" || pageTemplate === "ROOT";

  const showDesktopSubNavigation = !showShopNavi && pageTemplate === "CONTENT";

  const showBreadcrumbs = pageTemplate !== "ROOT";

  const intro = <ContentPageIntro path={path} />;
  return (
    <PageLayout
      showDesktopSubNavigation={showDesktopSubNavigation}
      NavItemsQuery={NavItemsQuery}
      fullWidthHeader={fullWidthIntro ? intro : null}
      showBreadcrumbs={showBreadcrumbs}
    >
      {!fullWidthIntro ? intro : null}

      <ContentPageHead path={path} />
      <ContentPageContent path={path} />
    </PageLayout>
  );
};

const ContentPage = ({ path }: { path: string }) => {
  const router = useRouter();

  // update hash to retrigger browser jump to hash. That is not really clean as it does not work on slow network / browser
  // but maybe cheap enough to work in most cases
  useEffect(() => {
    let handle;
    if (router.asPath && window.location.hash) {
      handle = setTimeout(function () {
        const id = window.location.hash.substring(1);
        browserScrollToWithOffset(`#${id}`, { offset: 180 });
      }, 600);
    }
    return () => clearTimeout(handle);
  }, [router.asPath]);

  const { page } = usePageBaseProps(path);

  const showShopNavi = useShouldShowShopNavi(
    (router.query?.menuPath as string) ?? path,
  );

  const NavItemsQuery = showShopNavi ? ShopNavItemsQuery : PageNavItemsQuery;

  const Content =
    page?.pageTemplate === PageTemplate.TIPS
      ? TipsPageContent
      : page?.pageTemplate === PageTemplate.TRAVEL_REPORTS
      ? TravelReportPageContent
      : page?.pageTemplate === PageTemplate.JOBS
      ? JobPageContent
      : page?.pageTemplate === PageTemplate.STORES
      ? StorePageContent
      : DefaultPageContent;

  return (
    <PagePathsProvider
      value={{
        fullPath: path,
        pagePath: page?.path,
      }}
    >
      <Content path={path} NavItemsQuery={NavItemsQuery} />
    </PagePathsProvider>
  );
};

export default memo(ContentPage);
