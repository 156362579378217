import makeNavItemFromAssortment from "../../navigation/utils/makeNavItemFromAssortment";

import useAssortment from "./useAssortment";

const useAssortmentNavItem = ({
  path,
  assortmentId,
  additionalQuery,
}: {
  path?: string;
  assortmentId?: string;
  additionalQuery?: string;
}) => {
  const { assortment, error, loading } = useAssortment({ assortmentId, path });

  const assortmentNavItem = assortment
    ? makeNavItemFromAssortment(assortment, additionalQuery)
    : null;

  return { assortmentNavItem, error, loading };
};

export default useAssortmentNavItem;
