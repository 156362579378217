import React from "react";
import { makeFullUrl } from "../../../utils/makeFullUrl";
import { useFullPage } from "../hooks/useFullPage";
import Seo from "./Seo";

export interface ContentPageHeadProps {
  path: string;
}

const ContentPageHead: React.FC<ContentPageHeadProps> = ({ path }) => {
  const { data } = useFullPage(path);
  if (!data || !data.page) {
    return null;
  }
  const { page } = data;

  const title = page.htmlTitle || page.navigationTitle;

  return (
    <Seo
      title={title}
      description={page?.meta?.description}
      canonical={makeFullUrl(page?.path)}
      openGraph={{
        description: page?.social?.description,
        title: page?.social?.title,
        images: page?.social?.image
          ? [
              {
                url: page?.social?.image,
              },
            ]
          : null,
      }}
    />
  );
};

export default ContentPageHead;
