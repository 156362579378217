import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { getWarehouseId } from "../../checkout/utils/getWarehouseId";
import type { GetStores, GetStoresVariables } from "./types/GetStores";

const QUERY = gql`
  query GetStores($includeJobPageOnlyStores: Boolean) {
    stores(
      includeJobPageOnlyStores: $includeJobPageOnlyStores
      orderBy: name_ASC
    ) {
      id
      name
      slug
      warehousingProviderId
      hideInAvailabilityAndClickAndPick
    }
  }
`;

const useStores = (includeJobPageOnlyStores = false) => {
  const { data, loading, error } = useQuery<GetStores, GetStoresVariables>(
    QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        includeJobPageOnlyStores,
      },
    },
  );
  const stores = data?.stores;
  const storeByWarehousingProviderId = stores?.reduce(
    (result, store) => ({
      ...result,
      [store.warehousingProviderId]: store,
    }),
    {},
  );

  const getPickupLocationStoreName = (pickUpLocation: {
    _id: string;
    name: string;
  }) => {
    const warehouseId = getWarehouseId(pickUpLocation._id);
    return (
      storeByWarehousingProviderId?.[warehouseId]?.name ?? pickUpLocation.name
    );
  };

  return {
    stores,
    storeByWarehousingProviderId,
    getPickupLocationStoreName,
    loading,
    error,
  };
};

export default useStores;
