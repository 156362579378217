import { PageTemplate } from "../../../types/global-types";
import ShopNavItemsQuery from "../../categories/components/ShopNavItemsQuery";
import usePageForNavigation from "../../navigation/hooks/usePageForNavigation";
import type { NavItemsQueryType } from "../../navigation/types";
import makeNavItemFromPage from "../../navigation/utils/makeNavItemFromPage";
import makeNavItemFromStore from "../../navigation/utils/makeNavItemFromStore";
import useStores from "../../stores/hooks/useStores";

const PageNavItemsQuery: NavItemsQueryType = ({ path, children, slug }) => {
  const { page } = usePageForNavigation(path);
  const { stores } = useStores();

  if (path === "/" || path.startsWith("/shop")) {
    return <ShopNavItemsQuery path={path} children={children} slug={slug} />;
  }

  if (page) {
    const currentNavItem = makeNavItemFromPage(page);
    const isExact = page?.path === path; // when processChildPaths is set, it might be a sub path

    if (!isExact) {
      return children({ navItems: [] }); // we are on a virtual child, don't show a page
    } else if (page.pageTemplate === PageTemplate.STORES) {
      const navItems =
        stores?.map((store) => makeNavItemFromStore(store, currentNavItem)) ??
        [];
      return children({ navItems, currentNavItem });
    } else {
      const navItems = page.childPages.map(makeNavItemFromPage);
      return children({ navItems, currentNavItem });
    }
  }
  return null;
};
export default PageNavItemsQuery;
