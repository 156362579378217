import dynamic from "next/dynamic";
import React from "react";
import ContentPageHead from "../../content/components/ContentPageHead";
import ContentPageIntro from "../../content/components/ContentPageIntro";
import usePagePaths from "../../content/hooks/usePagePaths";
import PageLayout from "../../layout/components/PageLayout";
import type { NavItemsQueryType } from "../../navigation/types";

const TravelReportDetail = dynamic(() => import("./TravelReportDetail"));

const TravelReportOverview = dynamic(() => import("./TravelReportOverview"));
const TravelReportTagFilterPanel = dynamic(
  () => import("./TravelReportTagFilterPanel"),
);
const TravelReportPageContent = ({
  path,
  NavItemsQuery,
}: {
  path: string;
  NavItemsQuery: NavItemsQueryType;
}) => {
  const { subPageId: travelReportId } = usePagePaths();

  return (
    <PageLayout
      desktopLeftSubNaviAdditionalContent={
        !travelReportId ? <TravelReportTagFilterPanel /> : null
      }
      showDesktopSubNavigation
      NavItemsQuery={NavItemsQuery}
      showBreadcrumbs
    >
      {travelReportId ? (
        <TravelReportDetail travelReportId={travelReportId} />
      ) : (
        <>
          <ContentPageHead path={path} />
          <ContentPageIntro path={path} />
          <TravelReportOverview path={path} />
        </>
      )}
    </PageLayout>
  );
};

export default TravelReportPageContent;
