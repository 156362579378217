import { ShopVPSAvailability } from "../../../types/global-types";

export const getMostOptimisticAvailability = (
  availabilities: ShopVPSAvailability[],
) => {
  if (availabilities.length === 0) {
    return ShopVPSAvailability.OUT_OF_STOCK;
  }

  const sortIndex = [
    ShopVPSAvailability.IMMEDIATE,
    ShopVPSAvailability.AVAILABLE,
    ShopVPSAvailability.DELAYED_AVAILABILITY,
    ShopVPSAvailability.UNKNOWN_AVAILABILITY,
    ShopVPSAvailability.SOLD,
    ShopVPSAvailability.OUT_OF_STOCK,
  ];

  return availabilities.sort(
    (a: any, b: any) => sortIndex.indexOf(a) - sortIndex.indexOf(b),
  )[0];
};
