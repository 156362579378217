import gql from "graphql-tag";
import { AssortmentParts } from "../../product/queries";
import type { AssortmentParts as AssortmentPartsType } from "../../product/queries/types/AssortmentParts";
import type {
  UseAssortment,
  UseAssortmentVariables,
} from "./types/UseAssortment";
import { useQuery } from "@apollo/client";
import { SHOP_PATH } from "../../../config/locations";
export const QUERY = gql`
  query UseAssortment($path: String, $assortmentId: ID) {
    assortment(slug: $path, assortmentId: $assortmentId) {
      ...AssortmentParts
    }
  }
  ${AssortmentParts}
`;

export const isBikeAssortment = (assortmentId: string) =>
  assortmentId?.startsWith("K3");

// currently hardcoded list, see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/1412
export const isTrailerAssortment = (assortmentId: string) =>
  ["K1-01", "K1-01-003", "K1-01-006", "K1-01-005", "K1-01-002"].includes(
    assortmentId,
  );

const useAssortment = ({
  path: pathRaw,
  assortmentId,
}: {
  path?: string;
  assortmentId?: string;
}) => {
  const path = pathRaw?.replace(SHOP_PATH, ""); // we now use full path,
  const { data, error, loading } = useQuery<
    UseAssortment,
    UseAssortmentVariables
  >(QUERY, {
    variables: { path, assortmentId },
    skip: !path && !assortmentId,
  });

  return {
    assortment: data?.assortment,
    error,
    loading,
    isBikeAssortment: isBikeAssortment(data?.assortment?._id),
    isTrailerAssortment: isTrailerAssortment(data?.assortment?._id),
  };
};

export default useAssortment;
