import styled, { css } from "styled-components";
import { useEmployee } from "../hooks/useEmployee";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";
import type { EmployeeFragment } from "../hooks/types/EmployeeFragment";
type ProfileImageSize = "small" | "medium";
type Align = "left" | "center" | "right";

type Layout = "vertical" | "horizontal";
type BaseProps = {
  align?: Align;
  layout?: Layout;
  size?: ProfileImageSize;
};
export type EmployeeInfoByIdProps = {
  employeeId: string;
} & BaseProps;

const ProfileImage = styled.img<{ size: ProfileImageSize }>`
  object-fit: cover;
  align-self: stretch;
  width: 75px;
  height: 75px;
  border-radius: 50%;

  ${iff((p) => p.size === "medium")`
     width: 100px;
     height: 100px;
  `}
`;

const NameWrapper = styled.div`
  ${selectFont("small")}
`;

const JobTitleWrapper = styled.div`
  ${selectFont("textDetails")};
  font-style: italic;
`;

const Base = styled.div<{ align: Align }>`
  display: flex;
  justify-content: ${(p) =>
    p.align === "left"
      ? "flex-start"
      : p.align === "center"
      ? "center"
      : "flex-end"};
`;

const Inner = styled.div<{ layout: Layout }>`
  display: flex;
  gap: 10px;
  ${(p) =>
    p.layout === "horizontal"
      ? css`
          flex-direction: row;
          gap: 20px;
          align-items: center;
        `
      : css`
          flex-direction: column;
        `}
`;
export const EmployeeInfo = ({
  employee,
  align,
  size,
  layout = "vertical",
}: {
  employee: Pick<
    EmployeeFragment,
    "firstName" | "lastName" | "jobTitle" | "profileImage"
  >;
} & BaseProps) => {
  const { firstName, lastName, jobTitle, profileImage } = employee ?? {};

  return (
    <Base align={align}>
      <Inner layout={layout}>
        <ProfileImage src={profileImage?.thumbUrl} size={size} />
        <div>
          <NameWrapper>
            {firstName} {lastName}
          </NameWrapper>
          {jobTitle ? <JobTitleWrapper>{jobTitle}</JobTitleWrapper> : null}
        </div>
      </Inner>
    </Base>
  );
};

export const EmployeeInfoById = ({
  employeeId,
  ...rest
}: EmployeeInfoByIdProps) => {
  const { data } = useEmployee({ id: employeeId });

  return <EmployeeInfo {...rest} employee={data?.employee} />;
};
