import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import type { UseDocument, UseDocumentVariables } from "./types/UseDocument";

export const GET_DOCUMENT = gql`
  query UseDocument($documentId: ID!) {
    document(id: $documentId) {
      id
      title
      file {
        contentType
        id
        src
      }
    }
  }
`;

const useDocument = (documentId: string) => {
  const { data, error, loading } = useQuery<UseDocument, UseDocumentVariables>(
    GET_DOCUMENT,
    {
      variables: { documentId },
      skip: !documentId,
    },
  );
  const document = data && data.document;

  return { document, error, loading };
};

export default useDocument;
