import dynamic from "next/dynamic";
import React from "react";
import ContentPageHead from "../../content/components/ContentPageHead";
import ContentPageIntro from "../../content/components/ContentPageIntro";
import usePagePaths from "../../content/hooks/usePagePaths";
import PageLayout from "../../layout/components/PageLayout";
import type { NavItemsQueryType } from "../../navigation/types";

const TipDetail = dynamic(() => import("./TipDetail"));
const TipOverview = dynamic(() => import("./TipOverview"));
const TipTagFilterPanel = dynamic(() => import("./TipTagFilterPanel"));

const TipsPageContent = ({
  path,
  NavItemsQuery,
}: {
  path: string;
  NavItemsQuery: NavItemsQueryType;
}) => {
  const { subPageId: tipId } = usePagePaths();

  return (
    <PageLayout
      showDesktopSubNavigation
      desktopLeftSubNaviAdditionalContent={
        !tipId ? <TipTagFilterPanel /> : null
      }
      NavItemsQuery={NavItemsQuery}
      showBreadcrumbs
    >
      {tipId ? (
        <TipDetail tipId={tipId} showBackButton />
      ) : (
        <>
          <ContentPageHead path={path} />
          <ContentPageIntro path={path} />
          <TipOverview path={path} />
        </>
      )}
    </PageLayout>
  );
};

export default TipsPageContent;
