import dynamic from "next/dynamic";
import React, { memo } from "react";
import ContentPageContent from "../../content/components/ContentPageContent";
import ContentPageHead from "../../content/components/ContentPageHead";
import ContentPageIntro from "../../content/components/ContentPageIntro";
import usePagePaths from "../../content/hooks/usePagePaths";
import PageLayout from "../../layout/components/PageLayout";
import type { NavItemsQueryType } from "../../navigation/types";

const JobDetail = dynamic(() => import("./JobDetail"));

const JobPageContent = ({
  path,
  NavItemsQuery,
}: {
  path: string;
  NavItemsQuery: NavItemsQueryType;
}) => {
  const { subPageId: jobId } = usePagePaths();

  return (
    <PageLayout
      showDesktopSubNavigation
      NavItemsQuery={NavItemsQuery}
      showBreadcrumbs
    >
      {jobId ? (
        <JobDetail jobId={jobId} />
      ) : (
        <>
          <ContentPageHead path={path} />
          <ContentPageIntro path={path} />
          <ContentPageContent path={path} />
        </>
      )}
    </PageLayout>
  );
};
export default memo(JobPageContent);
