import React from "react";
import styled from "styled-components";
import { selectColor, selectFont } from "../../../utils/themeUtils";
import mediaQueries from "../../../utils/mediaQueries";
import { useTranslation } from "../../../hooks/useTranslation";

const SIZE = {
  medium: 41,
  large: 57,
};

const Base = styled.div<{ size: Size }>`
  width: ${(p) => SIZE.medium}px;
  height: ${(p) => SIZE.medium}px;

  border-radius: 50%;
  background-color: ${selectColor("veloplusRot")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.tablet`
    width: ${(p) => SIZE[p.size]}px;
    height: ${(p) => SIZE[p.size]}px;
  `}
`;

type Size = "large" | "medium";

export interface DiscountBadgeProps {
  style?: React.CSSProperties;
  className?: string;
  size?: Size;
  discount: number;
  isMonthly?: boolean;
  isLowPrice?: boolean;
}

const Discount = styled.p<{ size: Size }>`
  transform: rotate(-7deg);
  color: ${selectColor("white")};
  ${selectFont((p) =>
    p.size === "large" ? "productDiscountLarge" : "productDiscount",
  )};
  line-height: 1.1;
`;
const DiscountLabel = styled.span`
  ${selectFont("productDiscount")};
  font-size: 7px;
  line-height: 1;
  transform: rotate(-7deg);
  color: ${selectColor("white")};

  ${mediaQueries.tablet`
    font-size: 9px;
  `}
`;

const BiggerFont = styled.span`
  font-size: 9px;
  ${mediaQueries.tablet`
    font-size: 13px;
  `}
`;

const DiscountBadge: React.FC<DiscountBadgeProps> = ({
  style,
  className,
  size = "medium",
  discount,
  isMonthly,
  isLowPrice,
}) => {
  const { t } = useTranslation("shop");
  if (!discount && !isLowPrice && !isMonthly) return null;
  return (
    <Base style={style} className={className} size={isMonthly ? "large" : size}>
      {discount && !isLowPrice ? (
        <Discount size={isMonthly ? "large" : size}>
          -{discount}%&nbsp;
        </Discount>
      ) : null}
      {isMonthly || isLowPrice ? (
        <DiscountLabel>
          {isMonthly ? (
            t("products.MONTHLY_DISCOUNTED")
          ) : size === "large" ? (
            <BiggerFont>{t("products.LOW_PRICE_DISCOUNTED")}</BiggerFont>
          ) : (
            t("products.LOW_PRICE_DISCOUNTED")
          )}
        </DiscountLabel>
      ) : null}
    </Base>
  );
};

export default DiscountBadge;
