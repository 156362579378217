import dynamic from "next/dynamic";
import React from "react";
import useDocument from "../hooks/useDocument";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import ALink from "./ALink";
import styled from "styled-components";
const FileIcon = dynamic({
  loader: () => import("../../icons/components/FileIcon"),
});

export interface DocumentLinkProps {
  documentId: string;
  id?: string;
}

const Base = styled(ALink)``;

const Icon = styled(FileIcon)`
  margin-right: 0.5em;
  margin-left: 0.5em;
  position: relative;
  top: 0.15em;
  height: 1.1em;
`;

export const FileLink: React.FC<{
  href?: string;
  onClick?: (e: any) => void;
  id?: string;
  title?: string;
  contentType?: string;
}> = ({ onClick, id, title, contentType, href, children }) => {
  return (
    <Base target="_blank" href={href} onClick={onClick} id={id}>
      <Icon contentType={contentType} />
      {children ?? title}
    </Base>
  );
};

const DocumentLink: React.FC<DocumentLinkProps> = ({
  documentId,
  children,
  id,
}) => {
  const { document } = useDocument(documentId);
  const onClick = usePreventOnClickInEditor();

  return (
    <FileLink
      href={document?.file?.src}
      onClick={onClick}
      id={id}
      title={document?.title}
      contentType={document?.file?.contentType}
    >
      {children}
    </FileLink>
  );
};

export default DocumentLink;
