import React from "react";
import styled, { css } from "styled-components";
import { ShopVPSAvailability } from "../../../types/global-types";
import { selectColor } from "../../../utils/themeUtils";

export interface DotProps {
  availability: ShopVPSAvailability;
}

const STYLES: {
  [key in ShopVPSAvailability]: any;
} = {
  AVAILABLE: css`
    border-left: 8px solid rgb(255, 255, 255);
    border-right: 8px solid ${selectColor("green")};
    border-bottom: 8px solid ${selectColor("green")};
    border-top: 8px solid ${selectColor("green")};
    transform: rotate(45deg);
  `,
  IMMEDIATE: css`
    background-color: ${selectColor("green")};
  `,
  DELAYED_AVAILABILITY: css`
    border-left: 8px solid ${selectColor("delayed")};
    border-right: 8px solid ${selectColor("delayedLight")};
    border-bottom: 8px solid ${selectColor("delayedLight")};
    border-top: 8px solid ${selectColor("delayedLight")};
    transform: rotate(45deg);
  `,
  OUT_OF_STOCK: css`
    background-color: ${selectColor("veloplusRot")};
  `,
  UNKNOWN_AVAILABILITY: css`
    border: 1px solid ${selectColor("veloplusRot")};
  `,
  SOLD: css`
    background-color: ${selectColor("veloplusRot")};
  `,
};

const Dot = styled.div<DotProps>`
  height: 16px;
  width: 16px;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
  ${(p) => STYLES[p.availability]}
`;

const CheckMark = styled.svg`
  padding-left: 1px;
  padding-top: 1px;
`;

const QuestionMarkIcon = styled.span.attrs({
  children: "?",
})`
  font-weight: bold;
  display: flex;
  justify-content: center;

  position: relative;
  left: 0.5px;
  top: 1px;
  color: ${selectColor("veloplusRot")};
  line-height: 1;
  font-size: 11px;
`;
export interface AvailabilityIconProps {
  style?: React.CSSProperties;
  className?: string;
  availability: ShopVPSAvailability;
}
const AvailabilityIcon: React.FC<AvailabilityIconProps> = ({
  style,
  className,
  availability,
}) => {
  return (
    <Dot style={style} className={className} availability={availability}>
      {availability === ShopVPSAvailability.IMMEDIATE ? (
        <CheckMark>
          <g fill="none" fillRule="evenodd">
            <path
              stroke="#FFF"
              strokeWidth="2"
              d="M3.038 7.208L5.768 10l4.252-6.966"
            />
          </g>
        </CheckMark>
      ) : availability === ShopVPSAvailability.UNKNOWN_AVAILABILITY ? (
        <QuestionMarkIcon />
      ) : null}
    </Dot>
  );
};

export default AvailabilityIcon;
